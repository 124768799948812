import {
  RiAlertFill,
  RiIndeterminateCircleFill,
  RiCheckboxBlankCircleFill,
  RiCloseCircleFill,
  RiInformationFill,
  RiPhoneFill,
  RiTimeFill
} from "@remixicon/react";

export const eventIcons = {
  approved: RiCheckboxBlankCircleFill,
  preapproved: RiCheckboxBlankCircleFill,
  instant_approved: RiCheckboxBlankCircleFill,
  declined: RiCloseCircleFill,
  instant_declined: RiCloseCircleFill,
  pending: RiAlertFill,
  instant_pending: RiAlertFill,
  cancelled: RiIndeterminateCircleFill,
  instructions: RiInformationFill,
  request_a_call: RiPhoneFill,
  time_changed: RiTimeFill
};

export const eventColors = {
  misc: "#2980b9", //Belize Hole
  approved: "#27ae60", //Nephritis
  preapproved: "#27ae60", //Nephritis
  instant_approved: "#27ae60", //Nephritis
  declined: "#c0392b", //Pomegranate
  instant_declined: "#c0392b", //Pomegranate
  pending: "#f1c40f", //Orange
  instant_pending: "#f1c40f", //Orange
  cancelled: "#34495e", //Wet Asphalt
  instructions: "#2980b9", //Belize Hole
  request_a_call: "#2980b9", //Belize Hole
  time_changed: "#2980b9" //Belize Hole
};
