import css from "./index.sass";
import React, { useContext, useState } from "react";
import { RiAlertFill } from "@remixicon/react";

import { Row, Col, Textarea } from "~brokerage/components/shared/Form";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import ListingSummary from "~brokerage/components/modals/ModalCreateShowing/ListingSummary";
import ListingCard from "~brokerage/components/modals/ModalCreateShowing/ListingCard";
import { MAX_MESSAGE_LENGTH } from "~brokerage/constants/showings";
import AgentInfo from "~brokerage/components/modals/ModalCreateShowing/AgentInfo";
import BuyerSection from "./BuyerSection";
import BuyerBrokerAgreement from "~brokerage/components/shared/BuyerBrokerAgreement";

const SendRequest = props => {
  const {
    listing: {
      photoUrl,
      mls,
      price,
      specs,
      contingencyString,
      status,
      address,
      buyerNameRequired
    },
    appointmentReason,
    buyers,
    setBuyers,
    createBuyer,
    buyerAgentId,
    mlsBuyerRequiredEnabled,
    mlsBuyerBrokerAgreementEnabled
  } = props;

  const [showingMessage, setMessage] = useState("");
  const [error, setError] = useState(null);
  const { times, setTimes } = useContext(ShowingContext);

  const handleChange = value => {
    if (value.length >= MAX_MESSAGE_LENGTH) {
      setError("Max message length reached.");
    } else {
      setError(null);
      setMessage(value);
      const update = { ...times[0], showingMessage: value };
      setTimes([update]);
    }
  };

  const isBuyerRequired = mlsBuyerRequiredEnabled && buyerNameRequired;

  const showBuyerRequiredText = isBuyerRequired && !buyerAgentId;

  return (
    <div className={css.msg}>
      <ListingSummary
        address={address}
        status={status}
        contingencyString={contingencyString}
        specs={specs}
        canScheduleShowingOnBehalfOfBuyerAgent={false}
      />
      <Row className={css.summaryRow}>
        {buyerAgentId && <AgentInfo agentId={buyerAgentId} />}
        <ListingCard
          price={price}
          mls={mls}
          photoUrl={photoUrl}
          appointmentReason={appointmentReason}
        />
        <Col modifier="full600" className={css.messageCol}>
          <Textarea
            className={css.textarea}
            divStyle={{ width: "100%" }}
            name="message"
            value={showingMessage}
            placeholder="Enter a message (optional)."
            onChange={e => handleChange(e.target.value)}
          />
          {error && <div className={css.error}>{error}</div>}
          {showBuyerRequiredText ? (
            <div className={css.buyerRequired}>
              <RiAlertFill size={30} className={css.icon} />
              <span>Buyer must be added to make an appointment request</span>
            </div>
          ) : (
            isBuyerRequired && (
              <span className={css.buyerNotRequired}>
                The Buyer required constraint has been lifted by the addition of
                showing agent
              </span>
            )
          )}
          {!buyerAgentId && (
            <div className={css.buyerSection}>
              <BuyerSection
                buyers={buyers}
                setBuyers={setBuyers}
                createBuyer={createBuyer}
              />
            </div>
          )}
        </Col>
      </Row>
      {mlsBuyerBrokerAgreementEnabled && <BuyerBrokerAgreement />}
    </div>
  );
};

export default SendRequest;
