import css from "./MyShowingsHeader.sass";
import React from "react";
import Button from "~brokerage/components/shared/Button";
import Icon from "~brokerage/components/shared/Icon";
import { RiMapPinTimeLine, RiHistoryLine } from "@remixicon/react";
import PropTypes from "prop-types";

const MyShowingsHeader = ({ location, setView, view }) => (
  <div className={css.layout}>
    <div className={css.left}>
      <Button
        variant="header2"
        active={view === "upcoming"}
        onClick={() => setView("upcoming")}
      >
        <i className={css.icon}>
          <RiMapPinTimeLine size={16} />
        </i>
        Upcoming Routes
      </Button>
      <Button
        variant="header2"
        active={view === "past"}
        onClick={() => setView("past")}
      >
        <i className={css.icon}>
          <RiHistoryLine size={16} />
        </i>
        Past Routes
      </Button>
    </div>
    <div className={css.right}>
      <Button
        to={{
          pathname: "/my_showings/create",
          state: { prevPath: location.pathname }
        }}
        variant="green"
      >
        <Icon name="plus" modifier="offset5 valignTextDefault" />
        Create New Route
      </Button>
    </div>
  </div>
);

MyShowingsHeader.propTypes = {
  location: PropTypes.object.isRequired
};

export default MyShowingsHeader;
