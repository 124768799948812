import React from "react";

import css from "./Header.sass";
import { RiCloseLine } from "@remixicon/react";

const Header = ({ headerText, closeModal }) => {
  return (
    <div className={css.mainHeader}>
      <div className={css.statusHeader}>{headerText}</div>
      <RiCloseLine
        onClick={closeModal}
        size="1.4em"
        className={css.closeIcon}
      />
    </div>
  );
};

export default Header;
