import css from "./ReportsHeader.sass";
import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "~brokerage/components/shared/Button";
import {
  RiPrinterLine,
  RiLineChartLine,
  RiPriceTag3Line
} from "@remixicon/react";
import Modal from "react-modal";
import printPopup from "./printPopup";

const ReportsHeader = ({ location, history, printEnabled }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handlePrint = () => {
    setModalOpen(false);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
      printPopup();
    } else {
      setTimeout(window.print, 500);
    }
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        className={css.modalContent}
        overlayClassName={css.modalOverlay}
        onRequestClose={() => setModalOpen(false)}
      >
        <div className={css.modalHeader}>Print Report</div>
        <div className={css.modalBody}>
          Your print options will appear next. Print as you see fit including
          changing the destination to PDF for a digital copy you can share with
          others.
        </div>
        <div className={css.modalFooter}>
          <Button variant="outline" onClick={() => setModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handlePrint}>
            OK
          </Button>
        </div>
      </Modal>

      <div className={css.base}>
        <div className={css.left}>
          <Button
            variant="header2"
            active={location.pathname === "/reports"}
            onClick={() => history.replace("/reports")}
          >
            <i className={css.icon}>
              <RiLineChartLine size={16} />
            </i>
            My Listings Activity
          </Button>
          <Button
            variant="header2"
            active={location.pathname === "/reports/price_analysis"}
            onClick={() => history.replace("/reports/price_analysis")}
          >
            <i className={css.icon}>
              <RiPriceTag3Line size={16} />
            </i>
            Price Point Analysis
          </Button>
        </div>
        <Button
          variant="green"
          disabled={!printEnabled}
          onClick={() => setModalOpen(true)}
          float="right"
        >
          <i className={css.icon}>
            <RiPrinterLine size={16} />
          </i>
          Print
        </Button>
      </div>
    </>
  );
};
export default withRouter(connect()(ReportsHeader));
