import React from "react";
import css from "./Buyer.sass";
import { RiCloseLine } from "@remixicon/react";

export default function Buyer({ name, onRemoveClick }) {
  return (
    <div className={css.buyer}>
      <p className={css.name}>{name}</p>
      <RiCloseLine className={css.closeIcon} onClick={onRemoveClick} />
    </div>
  );
}
