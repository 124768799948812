import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  fetchConversations,
  setRoleForMessages,
  updateConversationsFilter,
  searchMessages
} from "~brokerage/actions/messages";
import {
  fetchPreferencesIfNeeded,
  hideWelcomeScreen
} from "~brokerage/actions/myAccount";
import Content from "~brokerage/components/shared/Content";
import MessagesHeader from "~brokerage/components/messages/MessagesHeader";
import MessagesList from "~brokerage/components/messages/MessagesList";
import Dashboard from "~brokerage/components/messages/Dashboard";
import Show from "./show";
import {
  RiMailLine,
  RiMailOpenLine,
  RiMenuLine,
  RiCloseLine
} from "@remixicon/react";

export class Messages extends React.PureComponent {
  static propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    conversations: PropTypes.array,
    isFetching: PropTypes.bool,
    haveBeenPreferencesFetched: PropTypes.bool,
    welcomeScreenHidden: PropTypes.bool,
    hasAccessToTransactionsChat: PropTypes.bool,
    children: PropTypes.node,
    dispatch: PropTypes.func,
    searchTerm: PropTypes.string
  };

  state = {
    showMessagesPanel: false,
    showOptionsPanel: false
  };

  toggleMessagesPanel = () => {
    this.setState({ showMessagesPanel: !this.state.showMessagesPanel });
  };

  toggleOptionsPanel = () => {
    this.setState({ showOptionsPanel: !this.state.showOptionsPanel });
  };

  componentWillMount() {
    const { role, type } = this.props.location.query;
    const { searchTerm } = this.props;
    this.setRole(role);
    this.props.dispatch(updateConversationsFilter({ type, query: searchTerm }));
    if (searchTerm?.length > 2) {
      this.props.dispatch(searchMessages({ query: searchTerm, type: type }));
    } else {
      this.props.dispatch(fetchConversations({ type }));
    }
    this.props.dispatch(fetchPreferencesIfNeeded());
  }

  componentWillReceiveProps(nextProps) {
    const { role, type } = this.props.location.query;
    const { role: nextRole, type: nextType } = nextProps.location.query;
    const { searchTerm } = this.props;
    const { searchTerm: nextSearchTerm } = nextProps;
    if (nextRole !== role) {
      this.setRole(nextRole);
    }

    if (type !== nextType || searchTerm !== nextSearchTerm) {
      this.props.dispatch(
        updateConversationsFilter({ type: nextType, query: nextSearchTerm })
      );
      if (nextSearchTerm.length > 2) {
        this.props.dispatch(
          searchMessages({ query: nextSearchTerm, type: nextType })
        );
      } else {
        this.props.dispatch(fetchConversations({ type: nextType }));
      }
    }

    if (
      nextProps.conversations &&
      nextProps.match.params.conversationType !== "dashboard" &&
      nextProps.haveBeenPreferencesFetched
    ) {
      if (
        !nextProps.match.params.id ||
        (this.props.isFetching &&
          !nextProps.isFetching &&
          this.props.conversations !== nextProps.conversations)
      ) {
        if (!nextProps.match.params.id) {
          if (!nextProps.welcomeScreenHidden) {
            this.redirectToWelcomeScreen();
          } else {
            this.redirectToFirstConversationIfNeeded(nextProps);
          }
        }
      }
    }
  }

  redirectToWelcomeScreen() {
    const { history } = this.props;
    history.replace("/messages/dashboard");
  }

  redirectToFirstConversationIfNeeded(props) {
    const { history, location } = props;
    if (props.conversations.length) {
      const { type, id, forRole: role } = props.conversations[0];
      history.replace({
        ...location,
        pathname: `/messages/${type}/${id}`,
        query: { ...location.query, role }
      });
    }
  }

  setRole(role) {
    this.props.dispatch(setRoleForMessages(role));
  }

  onHideWelcomeScreenClick() {
    this.props.dispatch(hideWelcomeScreen());

    if (this.props.match.params.conversationType === "dashboard") {
      const { history } = this.props;
      history.replace({ ...location, pathname: "/" });
    }
  }

  render() {
    const {
      location,
      haveBeenPreferencesFetched,
      welcomeScreenHidden,
      hasAccessToTransactionsChat
    } = this.props;
    const params = this.props.match.params;
    const { conversationType: type, id } = params;
    const messagePanelDisplay = this.state.showMessagesPanel
      ? css.showMessagePanel
      : "";
    const mailButtonIcon = this.state.showMessagesPanel ? (
      <RiMailOpenLine size={20} color="white" />
    ) : (
      <RiMailLine size={20} color="white" />
    );
    const optionsButtonIcon = this.state.showOptionsPanel ? (
      <RiCloseLine size={20} color="white" />
    ) : (
      <RiMenuLine size={20} color="white" />
    );

    return (
      <div>
        <MessagesHeader location={location} history={this.props.history} />
        <div className={css.messagePanelControls}>
          <div className={css.messagePanelControlsLeft}>
            <button
              className={css.messagePanelControls__button_mail}
              onClick={this.toggleMessagesPanel}
            >
              {mailButtonIcon}
            </button>
            <button
              className={css.messagePanelControls__button_options}
              onClick={this.toggleOptionsPanel}
            >
              {optionsButtonIcon}
            </button>
          </div>
        </div>
        <Content className={css.contentPane} header="single">
          <div className={`${css.leftSidebar} ${messagePanelDisplay}`}>
            <div>
              <MessagesList
                currentConversationType={type}
                currentConversationId={id}
              />
            </div>
          </div>
          {type === "dashboard" ? (
            <Dashboard
              location={location}
              hasAccessToTransactionsChat={hasAccessToTransactionsChat}
              onHideWelcomeScreenClick={this.onHideWelcomeScreenClick.bind(
                this
              )}
            />
          ) : (
            id && (
              <Show
                params={params}
                showOptionsPanel={this.state.showOptionsPanel}
              />
            )
          )}
        </Content>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    entities: conversations,
    isFetching,
    isSearching,
    searchTerm
  } = state.messages.list.messages;
  const {
    welcomeScreenHidden,
    hasAccessToTransactionsChat,
    fetched: haveBeenPreferencesFetched
  } = state.myAccount.preferences;

  return {
    conversations,
    welcomeScreenHidden,
    isFetching,
    isSearching,
    searchTerm,
    hasAccessToTransactionsChat,
    haveBeenPreferencesFetched
  };
};
export default connect(mapStateToProps)(Messages);
