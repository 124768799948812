import React from "react";
import { RiMore2Fill } from "@remixicon/react";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import classNames from "classnames";
import { noop } from "lodash";

import css from "./index.sass";

const ActionsDropdown = ({
  showCancelAppointment,
  showReschedule,
  onCancelAppointment = noop,
  onPreapprove = noop,
  menuButtonClass = null
}) => {
  const buttonClass = classNames({
    [menuButtonClass]: menuButtonClass,
    [css.menuButton]: !menuButtonClass
  });

  return (
    <Menu
      menuButton={
        <MenuButton className={buttonClass}>
          <RiMore2Fill />
        </MenuButton>
      }
      direction="bottom"
      align="end"
      position="auto"
      viewScroll="auto"
      transition
    >
      {showCancelAppointment && (
        <MenuItem className={css.menuItem} onClick={onCancelAppointment}>
          Cancel Appointment
        </MenuItem>
      )}
      {showReschedule && (
        <MenuItem className={css.menuItem} onClick={onPreapprove}>
          Reschedule
        </MenuItem>
      )}
    </Menu>
  );
};

export default ActionsDropdown;
