import React from "react";
import { connect } from "react-redux";
import css from "./Details.sass";
import MlsAlignedLogo from "~brokerage/assets/images/mls-aligned-logo";

import {
  RiPhoneLine,
  RiMailLine,
  RiGlobalLine,
  RiSurveyLine
} from "@remixicon/react";

import Link from "~brokerage/components/shared/Link";
import mlsSupportData from "./mlsSupportData";

const Details = ({ systemName = "" }) => {
  //Todo: create GET mls/[id]/support endpoint to return this data.
  const uniqueId = systemName.toLowerCase();
  const mlsData = mlsSupportData[uniqueId];

  return (
    <div className={css.container}>
      <div className={css.header} />
      <div className={css.content}>
        <div className={css.left}>
          <h1>Aligned Showings Support</h1>
        </div>
        {mlsData ? (
          <div className={css.right}>
            <h3>
              <small>
                Brought to you by <br />
              </small>
              {` ${mlsData.name} `}
              <small>via</small> MLS Aligned
            </h3>
            <div className={css.logos}>
              <img src={`./img/mlsLogos/${uniqueId}.png`} />
              <img src={MlsAlignedLogo} />
            </div>
            <div className={css.contact}>
              {mlsData.phone && (
                <div className={css.contactRow}>
                  <RiPhoneLine size={18} color="#98acbb" />
                  <div className={css.contactDetail}>
                    <div>Phone</div>
                    <Link href={`tel:${mlsData.phone}`} external>
                      {mlsData.phone}
                    </Link>
                  </div>
                </div>
              )}
              {mlsData.email && (
                <div className={css.contactRow}>
                  <RiMailLine size={18} color="#98acbb" />
                  <div className={css.contactDetail}>
                    <div>Email</div>
                    <Link href={`mailto:${mlsData.email}`} external>
                      {mlsData.email}
                    </Link>
                  </div>
                </div>
              )}
              {mlsData.website && (
                <div className={css.contactRow}>
                  <RiGlobalLine size={18} color="#98acbb" />
                  <div className={css.contactDetail}>
                    <div>Website</div>
                    <Link href={mlsData.website} external>
                      {mlsData.website}
                    </Link>
                  </div>
                </div>
              )}
              {mlsData.feedback && (
                <div className={css.contactRow}>
                  <RiSurveyLine size={18} color="#98acbb" />
                  <div className={css.contactDetail}>
                    <div>Feedback</div>
                    <Link href={mlsData.feedback} external>
                      Aligned Showings Feedback
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={css.right}>
            <h3>Sorry, no support details were found.</h3>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ currentUser }) => {
  const { systemName } = currentUser;
  return {
    systemName
  };
};

export default connect(mapStateToProps)(Details);
