import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import moment from "moment";
import {
  RiMoneyDollarCircleLine,
  RiCameraLensFill,
  RiMapPinLine,
  RiInformationLine
} from "@remixicon/react";
import StatusPill from "~brokerage/components/shared/StatusPill";
import {
  formatTime,
  formatDate,
  formatAddress,
  getGoogleMapsLink
} from "./utils";
import { CUSTOM_MODAL_STYLE } from "./constants";
import css from "./Content.sass";
import DetailContainer from "./DetailContainer";
import Button from "~brokerage/components/shared/Button";
import { formatPrice } from "~brokerage/libs/helpers/FormatHelper";
import ShowingActions from "~brokerage/components/shared/ShowingActions";
import { callApi } from "~brokerage/middlewares/api";
import AppointmentStatusModal from "~brokerage/app/components/shared/AppointmentStatusModal/index.jsx";
import AppointmentEditTimeModal from "~brokerage/app/components/shared/AppointmentEditTimeModal";
import { toast } from "react-toastify";

const PopoverContent = ({
  data: {
    id: showingId,
    status,
    requested_time: requestedTime,
    duration,
    state_or_province,
    city,
    postal_code,
    price,
    contingency,
    mls_number: mlsNumber,
    listing_status: listingStatus,
    photo_url: photoUrl,
    street = ""
  },
  openShowingModal,
  updateCallback,
  closePopover
}) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState({});
  const [currentStatus, setCurrentStatus] = useState(status);
  const [isDateTimeModalOpen, setIsDateTimeModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState(status);
  const [editStatusModalOpen, setEditStatusModalOpen] = useState(false);
  const [isListingAgentPreapproving, setIsListingAgentPreapproving] =
    useState(false);

  useEffect(() => {
    setCurrentStatus(newStatus);
  }, [newStatus]);

  useEffect(() => {
    if (showingId) {
      fetchShowingDetails();
    }
  }, [showingId]);

  const fetchShowingDetails = () => {
    setLoading(true);
    callApi(`appointment_details/${showingId}`, {}, {}, "get")
      .then(response => {
        if (response.data && response.data.agent_contacts) {
          setDetails(response.data);
          setCurrentStatus(status);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Invalid API response for appointment details");
        }
      })
      .catch(err => {
        setLoading(false);
        toast.error(err);
      });
  };

  const openStatusModal = newStatus => {
    setNewStatus(newStatus);
    setEditStatusModalOpen(true);
  };

  const refreshCallback = () => {
    setCurrentStatus(newStatus);
    updateCallback();
  };

  const onAppointmentEditTimeModalClose = () => {
    setIsDateTimeModalOpen(false);
    setIsListingAgentPreapproving(false);
    closePopover();
  };

  const {
    agent_contacts: {
      user_relation: {
        is_showing_agent: isShowingAgent = false,
        is_listing_agent: isListingAgent = false,
        is_co_listing_agent: isCoListingAgent = false,
        is_showing_team: isShowingTeam = false,
        is_listing_team: isListingTeam = false
      } = {}
    } = {},
    unique_id: uniqueId,
    route: { route_id: routeId } = {},
    requested_time: updatedRequestedTime
  } = details;

  const showingRequestedTime = updatedRequestedTime || requestedTime;

  const timeSpan = formatTime(showingRequestedTime, duration);
  const eventDate = formatDate(showingRequestedTime);
  const address = formatAddress(city, state_or_province, postal_code);
  const priceText = formatPrice(price);
  const googleMapsLink = getGoogleMapsLink(street, address);

  const contingencyString = ` ${contingency ?? ""}`;
  const isAgentOnShowingTeam =
    details.agent_contacts && (isShowingAgent || isShowingTeam);
  const isAgentOnListingTeam =
    details.agent_contacts &&
    (isListingAgent || isCoListingAgent || isListingTeam);

  return (
    !loading && (
      <div className={css.container}>
        <div className={css.header}>
          <div className={css.dateContainer}>
            <div className={css.date}>{eventDate}</div>
            <div className={css.time}>{timeSpan}</div>
          </div>
          <StatusPill
            showingStatus={currentStatus}
            futureShowing={moment(showingRequestedTime).isAfter()}
            style={{ float: "right" }}
          />
        </div>
        <div className={css.body}>
          <div className={css.photoContainer}>
            <div
              className={css.listingStatus}
            >{`${listingStatus}${contingencyString}`}</div>
            {photoUrl ? (
              <img className={css.photo} src={photoUrl} alt="" />
            ) : (
              <RiCameraLensFill color="rgba(255, 255, 255, 0.6)" size={64} />
            )}
          </div>
          <div className={css.details}>
            <DetailContainer
              DetailIcon={RiMapPinLine}
              detailTextArray={[street, address]}
              link={googleMapsLink}
            />
            {priceText && (
              <DetailContainer
                DetailIcon={RiMoneyDollarCircleLine}
                detailTextArray={[`${priceText}`]}
              />
            )}
            <DetailContainer
              DetailIcon={RiInformationLine}
              detailTextArray={[`MLS# ${mlsNumber}`]}
            />
            <div className={css.showingActions}>
              <ShowingActions
                showingStatus={currentStatus}
                openStatusModal={openStatusModal}
                isShowingTeam={isAgentOnShowingTeam}
                isListingTeam={isAgentOnListingTeam}
                futureShowing={moment(showingRequestedTime).isAfter()}
                openDateTimeModal={() => setIsDateTimeModalOpen(true)}
                setIsListingAgentPreapproving={setIsListingAgentPreapproving}
              />
            </div>
            <div onClick={e => e.stopPropagation()}>
              {editStatusModalOpen && (
                <Modal
                  isOpen={true}
                  onRequestClose={() => {}}
                  style={CUSTOM_MODAL_STYLE}
                >
                  <AppointmentStatusModal
                    showingId={uniqueId}
                    newStatus={newStatus}
                    closeModal={() => setEditStatusModalOpen(false)}
                    refreshCallback={refreshCallback}
                  />
                </Modal>
              )}
            </div>
            <div onClick={e => e.stopPropagation()}>
              {isDateTimeModalOpen && (
                <AppointmentEditTimeModal
                  showingId={uniqueId}
                  closeModal={onAppointmentEditTimeModalClose}
                  refreshCallback={refreshCallback}
                  page={routeId ? "time" : "date"}
                  canEditDate={!routeId}
                  rescheduleAndApproveShowing={isListingAgentPreapproving}
                />
              )}
            </div>
            <div className={css.buttonContainer}>
              <Button onClick={openShowingModal} variant="link">
                More Details
              </Button>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PopoverContent;
