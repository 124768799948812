import {
  RiCalendarLine,
  RiMapPin2Line,
  RiHomeSmileLine,
  RiMailLine,
  RiContactsLine,
  RiStopCircleFill,
  RiFileChartLine,
  RiQuestionLine,
  RiNotification4Line,
  RiSettings3Line,
  RiLightbulbLine,
  RiMapPinTimeLine,
  RiEyeOffLine
} from "@remixicon/react";

const navigationIcons = {
  calendar: RiCalendarLine,
  routes: RiMapPin2Line,
  myListings: RiHomeSmileLine,
  notifications: RiNotification4Line,
  messages: RiMailLine,
  contacts: RiContactsLine,
  adminPanel: RiStopCircleFill,
  reports: RiFileChartLine,
  support: RiQuestionLine,
  settings: RiSettings3Line,
  learning: RiLightbulbLine,
  newShowing: RiMapPinTimeLine,
  stopImpersonating: RiEyeOffLine
};

export const navigationIcon = name => navigationIcons[name];
