import React from "react";
import classNames from "classnames";
import { Row } from "~brokerage/components/shared/Form";
import {
  APPOINTMENT_COMPLIANCE_FILE_URL,
  APPOINTMENT_COMPLIANCE_TEXT
} from "./constants";
import { RiAlertFill } from "@remixicon/react";

import css from "./index.sass";

const BuyerBrokerAgreement = ({ actionText = "SEND", path = "showing" }) => (
  <Row>
    <div
      className={classNames(css.buyerBrokerAgreement, {
        [css.buyerBrokerAgreementOnRoute]: path !== "showing"
      })}
    >
      <RiAlertFill className={css.icon} />
      <div className={css.text}>
        <span className={css.shortText}>
          {`By clicking on ${actionText}, you agree to the terms set forth`}
          &nbsp;
          <a
            href={APPOINTMENT_COMPLIANCE_FILE_URL}
            target="__blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>
        </span>
        <span className={css.longText}>
          {`By clicking ${actionText}, ${APPOINTMENT_COMPLIANCE_TEXT}`}
        </span>
      </div>
    </div>
  </Row>
);

export default BuyerBrokerAgreement;
