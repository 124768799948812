import React from "react";
import css from "./FilterSwitch.sass";
import { RiCheckboxBlankLine, RiCheckboxFill } from "@remixicon/react";

const FilterSwitch = ({ value, onClick, text, color }) => {
  return (
    <div onClick={onClick} className={css.filterContent}>
      {value ? (
        <RiCheckboxFill className={css.icon} />
      ) : (
        <RiCheckboxBlankLine className={css.icon} />
      )}
      <div className={css.status} style={{ color }}>
        {text}
      </div>
    </div>
  );
};

export default FilterSwitch;
