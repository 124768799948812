import React, { Component } from "react";
import { RiAccountCircleLine, RiLockLine } from "@remixicon/react";
import { externalApi } from "~brokerage/middlewares/api";
import OldInterfaceIframe from "brokerage/app/components/shared/OldInterfaceIframe";
import SplashScreen from "~brokerage/app/components/login/SplashScreen";
import AppLogo from "~brokerage/app/components/login/AppLogo";
import MlsAlignedLogo from "~brokerage/app/components/login/MlsAlignedLogo";
import css from "./layout.sass";
import {
  DEFAULT_LANDING_PAGE_URL,
  LOGIN_URL
} from "~brokerage/app/constants/apiUrls";
import Button from "~brokerage/app/components/shared/Button";
export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      invalidLogin: false,
      isForgotPasswordOpen: false,
      errorMessage: ""
    };
  }

  handleSubmit = () => {
    event.preventDefault();
    const { email, password } = this.state;

    let payload = {
      user: {
        email,
        password
      }
    };
    externalApi(LOGIN_URL, {}, payload, "post")
      .then(this.afterSignIn)
      .catch(error => {
        this.setState({
          invalidLogin: true,
          errorMessage: error.response.data.message
        });
      });
  };

  afterSignIn = data => {
    const { redirect_to: redirectUrl } = data.data;
    window.location = redirectUrl || DEFAULT_LANDING_PAGE_URL;
  };

  setFormFieldValue = name => {
    this.setState({ [name]: event.target.value, invalidLogin: false });
  };

  openForgotPasswordPage = () => {
    this.setState({ isForgotPasswordOpen: true });
  };

  renderLoginForm = () => {
    const { email, password, invalidLogin, errorMessage } = this.state;
    const fullWidth = { width: "100%", textAlign: "center", maxWidth: "400px" };

    return (
      <div className={css.formSection}>
        <AppLogo />
        <form className={css.loginForm} onSubmit={this.handleSubmit}>
          <div className={css.loginFormField}>
            <RiAccountCircleLine className={css.icon} color="#6E8593" />
            <input
              placeholder="Email Address"
              id="email"
              type="email"
              onChange={() => {
                this.setFormFieldValue("email");
              }}
              className={css.input}
            />
          </div>
          <div className={css.loginFormField}>
            <RiLockLine className={css.icon} color="#6E8593" />
            <input
              placeholder="Password"
              id="password"
              type="password"
              onChange={() => {
                this.setFormFieldValue("password");
              }}
              className={css.input}
            />
          </div>
          <p
            className={
              invalidLogin ? css.invalidMessage : css.invalidMessageHidden
            }
          >
            {errorMessage}
          </p>
          <Button
            disabled={password === "" || email === ""}
            type="submit"
            variant="primary"
            modifier="bigger fullWidth"
            style={fullWidth}
          >
            Continue
          </Button>
          <Button
            to={"/#"}
            variant="secondary"
            modifier="bigger fullWidth"
            style={fullWidth}
          >
            Back
          </Button>
        </form>
        <MlsAlignedLogo />
      </div>
    );
  };

  render() {
    const { isForgotPasswordOpen } = this.state;

    if (isForgotPasswordOpen) {
      return (
        <OldInterfaceIframe
          url={"/users/password/new"}
          location={this.props.location}
        />
      );
    }

    return (
      <div className={css.homePage}>
        <div className={css.loginContainer}>
          <SplashScreen />
          {this.renderLoginForm()}
        </div>
      </div>
    );
  }
}
