import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { RiLayoutGridFill, RiLayoutRowFill } from "@remixicon/react";

import ListingStatus from "~brokerage/components/listings/ListHeader/ListingStatus";
import { formatNumberUsingCommas } from "~brokerage/libs/helpers/FormatHelper";
import css from "~brokerage/components/listings/ListHeader/index.sass";
import { toggleListDisplay } from "~brokerage/actions/listings";

const ToggleButton = ({ active, Icon, position }) => {
  const divClassName =
    (active ? css.toggleActive : css.toggleInactive) + " " + position;
  return (
    <div className={divClassName}>
      <Icon className={css.icon} size={21} />
    </div>
  );
};

const ListHeader = ({ location, listingsCount, displayCards, dispatch }) => {
  return (
    <div className={css.base}>
      <div className={css.status}>
        <ListingStatus location={location} />
      </div>
      {listingsCount >= 0 && (
        <div className={css.total}>
          {listingsCount === 0
            ? listingsCount
            : formatNumberUsingCommas(listingsCount)}{" "}
          {listingsCount === 1 ? "Listing" : "Listings"}
        </div>
      )}
      <div
        className={css.cardToggle}
        onClick={() => dispatch(toggleListDisplay())}
      >
        <ToggleButton
          active={displayCards}
          Icon={RiLayoutGridFill}
          position={css.leftButton}
        />
        <ToggleButton
          active={!displayCards}
          Icon={RiLayoutRowFill}
          position={css.rightButton}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { total: listingsCount } = state.listings.list.listings;
  const { displayCards } = state.listings.list.display;
  return { listingsCount, displayCards };
}

ListHeader.propTypes = {
  location: PropTypes.object,
  listingsCount: PropTypes.number
};
export default connect(mapStateToProps)(ListHeader);
