import css from "./index.sass";
import React, { useContext, useEffect, useState } from "react";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { Footer as FooterEl } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { RiReplyFill } from "@remixicon/react";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import { isForbidden } from "~brokerage/app/helpers/showingConflicts.js";
import {
  updateRequestTimePayload,
  steps,
  preapprovePayload,
  updateRequestedTimeApi,
  preapproveShowingApi
} from "./utils";
import { STEPS, STEPS_WITH_PREVIOUS } from "./constants";

const Footer = ({
  currentStep,
  setCurrentStep,
  showingId,
  closeModal,
  canEditDate,
  refreshCallback,
  rescheduleAndApproveShowing,
  showingAccess
}) => {
  const [disabled, setDisabled] = useState(false);
  const { times } = useContext(ShowingContext);

  useEffect(() => {
    if (currentStep === STEPS.time && times[0]) {
      setDisabled(isForbidden(times[0]));
    }
  }, [currentStep, times]);

  const handleNext = () => {
    switch (currentStep) {
      case STEPS.date:
        setCurrentStep(STEPS.time);
        break;
      case STEPS.time:
        if (rescheduleAndApproveShowing) {
          setCurrentStep(STEPS.approval);
        } else {
          editShowingTime();
        }
        break;
      case STEPS.approval:
        preapproveShowing();
        break;
      default:
        break;
    }
  };

  const handlePrevious = () => {
    if (currentStep === STEPS.time) {
      setDisabled(false);
      setCurrentStep(STEPS.date);
    }

    if (currentStep === STEPS.approval) {
      setCurrentStep(STEPS.time);
    }
  };

  const performAction = async (
    apiFunction,
    payload,
    successMessage,
    errorMessage
  ) => {
    try {
      setDisabled(true);
      await callApi(apiFunction, {}, { ...payload }, "patch");
      toast.success(successMessage);
      refreshCallback();
      closeModal();
    } catch (error) {
      toast.error(error.response?.data?.error || errorMessage);
    } finally {
      setDisabled(false);
    }
  };

  const preapproveShowing = async () => {
    const payload = preapprovePayload(times[0], showingAccess);
    await performAction(
      preapproveShowingApi(showingId),
      payload,
      "Showing preapproved successfully",
      "Sorry, there was an error preapproving this showing. Please try again."
    );
  };

  const editShowingTime = async () => {
    const payload = updateRequestTimePayload(times[0]);
    await performAction(
      updateRequestedTimeApi(showingId),
      payload,
      "Showing time edited successfully.",
      "Sorry, there was an error scheduling this showing. Please try again."
    );
  };

  return (
    <FooterEl>
      <div className={css.buttonContainer}>
        {STEPS_WITH_PREVIOUS.includes(currentStep) && canEditDate && (
          <Button
            type="button"
            float="right"
            variant="outline"
            onClick={handlePrevious}
          >
            <RiReplyFill size={14} className={css.prevIcon} />
            Previous
          </Button>
        )}
        <Button
          type="submit"
          float="right"
          variant="primary"
          onClick={handleNext}
          disabled={disabled}
        >
          {steps[currentStep].footerButtonLabel(rescheduleAndApproveShowing)}
        </Button>
      </div>
    </FooterEl>
  );
};

export default Footer;
