import React from "react";
import { RiNotification2Fill } from "@remixicon/react";
import Switch from "~brokerage/components/shared/Switch";
import Tooltip from "~brokerage/components/shared/Tooltip";
import css from "./BroadcastOptOutToggle.sass";

const BroadcastOptOutToggle = ({
  broadcastEnabled,
  onChange,
  compactView = true
}) => {
  const broadcastStatus = broadcastEnabled ? "ON" : "OFF";

  return (
    <Tooltip text="Receive broadcast messages from this listing">
      <div className={css.broadcastToggle}>
        {compactView ? (
          <div>
            <RiNotification2Fill size={20} />
          </div>
        ) : (
          <div className={css.broadcastLabel}>Broadcast messages</div>
        )}
        <Switch
          name="changeBroadcast"
          checked={broadcastEnabled}
          onChange={onChange}
          label={broadcastStatus}
        />
      </div>
    </Tooltip>
  );
};

export default BroadcastOptOutToggle;
