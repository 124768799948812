import React from "react";
import css from "./FilterToggle.sass";

import {
  RiFilterLine,
  RiArrowRightSLine,
  RiArrowLeftSLine
} from "@remixicon/react";

const FilterToggle = ({ showPanel, onToggle }) => (
  <div className={css.filterToggle}>
    <button className={css.filterButton} onClick={onToggle}>
      <RiFilterLine size={20} color="white" />
      {showPanel ? (
        <RiArrowLeftSLine size={20} color="white" />
      ) : (
        <RiArrowRightSLine size={20} color="white" />
      )}
    </button>
  </div>
);

export default FilterToggle;
