import css from "./ListItem.sass";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RiEyeLine, RiPencilFill } from "@remixicon/react";

import {
  addRecipientToNewMessageRecipients,
  clearNewMessageRecipients
} from "~brokerage/actions/messages";
import Avatar from "~brokerage/components/shared/Avatar";
import Icon from "~brokerage/components/shared/Icon";
import TruncatedCell from "~brokerage/components/shared/Table/TruncatedCell";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { format as formatPhone } from "~brokerage/libs/helpers/PhonesHelper";
import { withRouter } from "react-router-dom";
import {
  formattedCityState,
  isBuyerCategory,
  isSellerTenantCategory,
  isMyAccountsCategory
} from "./utils";

const ListItem = (props, context) => {
  const {
    location,
    location: { query },
    location: {
      query: { category }
    },
    history,
    dispatch,
    person,
    isBrokerageTabOpenedByBroker,
    hideEmail,
    hidePhone
  } = props;
  const { currentUser } = context;
  const { isImpersonating } = currentUser;
  const isCategoryBuyer = isBuyerCategory(category);
  const isCategorySellerTenant = isSellerTenantCategory(category);
  const isCategorySellerTenantBuyer = isCategorySellerTenant || isCategoryBuyer;
  const isCategoryMyAccounts = isMyAccountsCategory(category);

  const handleClick = () => {
    if (isCategorySellerTenantBuyer) {
      return;
    }
    if (category === "customers")
      history.push({
        pathname: `/people/contact/${person.id}`,
        query
      });
    else
      history.push({
        pathname: `/people/${person.userId}`,
        query
      });
  };

  const handleNewMessageButtonClick = event => {
    dispatch(clearNewMessageRecipients());
    dispatch(addRecipientToNewMessageRecipients(person));

    history.push({
      pathname: location.pathname,
      query: { ...query, modal: "new_message" }
    });

    event.stopPropagation();
  };

  const renderActionsCell = () => {
    if (person.isCurrentUser) {
      return <td className={css.tdNoWrap} />;
    } else {
      return (
        <td className={css.tdNoWrap} onClick={handleNewMessageButtonClick}>
          <Tooltip text="New message" direction="up">
            <Icon name="message" />
          </Tooltip>
        </td>
      );
    }
  };

  const renderImpersonationActionCell = () => {
    if (person.isCurrentUser) {
      return <td className={css.tdNoWrap} />;
    } else {
      return (
        <td className={css.tdNoWrap} onClick={handleImpersonateClick}>
          <Tooltip text="Impersonate" direction="up">
            <RiEyeLine size={18} />
          </Tooltip>
        </td>
      );
    }
  };

  const handleImpersonateClick = event => {
    const { id: personId } = person;

    history.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        modal: "impersonate_user",
        personId
      }
    });

    event.stopPropagation();
  };

  const Actions = () => {
    if (isCategorySellerTenantBuyer)
      return (
        <div className={css.actions}>
          <div className={css.iconButton} onClick={handleEditClick}>
            <Tooltip text="Edit" direction="up">
              <RiPencilFill size={17} className={css.icon} />
            </Tooltip>
          </div>
        </div>
      );
    else return null;
  };

  const handleEditClick = () => {
    const { id: contactId } = person;

    history.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        modal: "update_contact",
        contactId: contactId
      }
    });
  };

  return (
    <tr className={css.tr} onClick={handleClick}>
      <td className={css.nameColumn}>
        <div className={css.avatar}>
          <Avatar
            size="30"
            src={person.photoUrl}
            name={[person.firstName, person.lastName].join(" ")}
          />
        </div>
        <div className={css.personName}>{person.name}</div>
        <Actions />
      </td>
      {!isCategorySellerTenantBuyer && renderActionsCell()}
      {(isBrokerageTabOpenedByBroker || isCategoryMyAccounts) &&
        !isImpersonating &&
        renderImpersonationActionCell()}
      {!hideEmail && <td className={css.td}>{person.email}</td>}
      {!hidePhone && (
        <td className={css.td}>
          {person.phone ? formatPhone(person.phone) : "-"}
        </td>
      )}
      {!isCategorySellerTenantBuyer && (
        <>
          <td className={css.td}>{person.brokerageName}</td>
          <td className={css.td}>{formattedCityState(person)}</td>
        </>
      )}
      {isCategorySellerTenantBuyer && (
        <td className={css.td}>{person.createdAt}</td>
      )}
      {isCategorySellerTenant && (
        <td className={css.td}>{person.lastVisitedTimestamp}</td>
      )}
      {isCategorySellerTenantBuyer && (
        <TruncatedCell content={person.notes} id={`notes-${person.id}`} />
      )}
    </tr>
  );
};

ListItem.contextTypes = {
  currentUser: PropTypes.object
};

ListItem.propTypes = {
  person: PropTypes.shape({
    id: PropTypes.string,
    userId: PropTypes.number,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    brokerageName: PropTypes.string,
    isCurrentUser: PropTypes.bool,
    createdAt: PropTypes.string,
    lastVisitedTimestamp: PropTypes.string
  }),
  location: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func
};

export default withRouter(connect()(ListItem));
