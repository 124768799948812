import css from "./index.sass";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Form,
  Row,
  Textarea,
  TextField
} from "~brokerage/components/shared/Form";
import { Header, Footer, Body } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import RecipientsInput from "./RecipientsInput";
import { MODES } from "./RecipientsInput";
import Tabs from "~brokerage/components/shared/Tabs";
import PremiumButtonWrapper, {
  PREMIUM_FEATURE_TYPES
} from "~brokerage/components/shared/PremiumButtonWrapper";
import {
  addRecipientToNewMessageRecipients,
  removeRecipientFromNewMessageRecipients,
  createDirectConversation,
  clearNewMessageRecipients,
  unsetCreatedConversationId,
  preserveNewMessageGlobalState,
  clearPreserveNewMessageGlobalState,
  clearNewMessageForm
} from "~brokerage/actions/messages";
import { withRouter } from "react-router-dom";
import { RiInformationLine } from "@remixicon/react";

export class ModalNewMessage extends React.PureComponent {
  state = {
    messageChanged: false,
    message: "",
    recipientsMode: MODES.ONE
  };

  static propTypes = {
    recipients: PropTypes.array,
    createdConversationId: PropTypes.number,
    message: PropTypes.string,
    isPosting: PropTypes.bool,
    location: PropTypes.object,
    history: PropTypes.object,
    dispatch: PropTypes.func
  };

  componentWillMount() {
    this.setState({ message: this.props.message });
    this.props.dispatch(clearPreserveNewMessageGlobalState());
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.createdConversationId) {
      this.props.history.push(
        `/messages/direct/${nextProps.createdConversationId}`
      );
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearNewMessageForm());

    this.props.dispatch(unsetCreatedConversationId());
  }

  get isDirectChat() {
    return this.state.recipientsMode === MODES.ONE;
  }

  get isGroupChat() {
    return this.state.recipientsMode === MODES.MANY;
  }

  get hasMessage() {
    return Boolean(this.state.message.length);
  }

  get hasEnoughRecipients() {
    const minimumRecipients = this.isDirectChat ? 1 : 2;
    return Boolean(
      this.props.recipients && this.props.recipients.length >= minimumRecipients
    );
  }

  get isSubmitAllowed() {
    return this.hasEnoughRecipients && this.hasMessage && !this.props.isPosting;
  }

  get shouldFocusMessageInput() {
    return this.hasEnoughRecipients;
  }

  handleSubmit = (event, values) => {
    const recipientIDs = this.props.recipients.map(
      recipient => recipient.userId
    );
    this.props.dispatch(
      createDirectConversation(recipientIDs, values.name, values.message)
    );
  };

  handleRemoveRecipient = recipient => {
    if (recipient) {
      this.props.dispatch(removeRecipientFromNewMessageRecipients(recipient));
    }
  };

  handleAddRecipient = recipient => {
    if (recipient) {
      this.props.dispatch(addRecipientToNewMessageRecipients(recipient));
    }
  };

  closeModalWindow = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      query: { ...this.props.location.query, modal: void 0 }
    });
  };

  handleMessageChange = event => {
    this.setState({ messageChanged: true, message: event.target.value.trim() });
  };

  handleModeSelection = mode => {
    this.props.dispatch(clearNewMessageRecipients());
    this.setState({
      recipientsMode: mode
    });
  };

  preserveGlobalState = () => {
    this.props.dispatch(preserveNewMessageGlobalState(this.state.message));
  };

  renderTextArea = () => {
    if (this.state.messageChanged) {
      return (
        <Textarea
          name="message"
          placeholder="Enter message"
          focused={this.shouldFocusMessageInput}
          onChange={this.handleMessageChange}
          required
        />
      );
    } else {
      return (
        <Textarea
          name="message"
          placeholder="Enter message"
          focused={this.shouldFocusMessageInput}
          onChange={this.handleMessageChange}
          value={this.state.message}
          required
        />
      );
    }
  };

  render() {
    return (
      <div className={css.base}>
        <Form onSubmit={this.handleSubmit} validate>
          <Header title="New Message" />

          <Body>
            <Row offset="0">
              <Tabs.Links variant="modal">
                <Tabs.Link
                  variant="modal"
                  active={this.isDirectChat}
                  label="Personal Message"
                  onClick={this.handleModeSelection.bind(this, MODES.ONE)}
                />
                <PremiumButtonWrapper
                  featureType={PREMIUM_FEATURE_TYPES.MULTIPLE_PEOPLE}
                  beforeOnClick={this.preserveGlobalState}
                >
                  <Tabs.Link
                    variant="modal"
                    active={this.isGroupChat}
                    label="Group Message"
                    onClick={this.handleModeSelection.bind(this, MODES.MANY)}
                  />
                </PremiumButtonWrapper>
              </Tabs.Links>
            </Row>
            {this.isGroupChat && (
              <Row offset="10">
                <div className={css.groupInfo}>
                  <RiInformationLine
                    color="#999"
                    size="1.2em"
                    className={css.infoIcon}
                  />
                  <p className={css.groupInfoText}>
                    Group Message is restricted to in-office team members only
                  </p>
                </div>
              </Row>
            )}

            <Row offset="10">
              <RecipientsInput
                recipients={this.props.recipients}
                handleAddRecipient={this.handleAddRecipient}
                handleRemoveRecipient={this.handleRemoveRecipient}
                mode={this.state.recipientsMode}
              />
            </Row>
            {this.isGroupChat && (
              <Row offset="10">
                <TextField
                  block={true}
                  name="name"
                  placeholder="Please enter group chat name (optional)"
                />
              </Row>
            )}
            <Row offset="10">{this.renderTextArea()}</Row>
          </Body>

          <Footer>
            <Button
              type="submit"
              float="right"
              variant="primary"
              disabled={!this.isSubmitAllowed}
            >
              Send Message
            </Button>
            <Button
              type="button"
              variant="newMessageCancel"
              onClick={this.closeModalWindow}
            >
              Cancel
            </Button>
          </Footer>
        </Form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { entities: recipients } = state.messages.new.recipients;
  const { message } = state.messages.new.savedMessage;
  const { createdConversationId, isPostingNewMessage: isPosting } =
    state.messages.list.messages;

  return {
    recipients,
    createdConversationId,
    message,
    isPosting
  };
}
export default withRouter(connect(mapStateToProps)(ModalNewMessage));
