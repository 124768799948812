import React from "react";
import PropTypes from "prop-types";
import css from "./TravelTime.sass";
import { RiRoadsterFill } from "@remixicon/react";

const TravelTime = ({ travel_time }) => {
  if (travel_time === 0) {
    return <></>;
  }
  return (
    <div className={css.travelTime}>
      <div>
        <RiRoadsterFill size={16} className={css.icon} /> Travel Time
      </div>
      <div className={css.time}>{travel_time} min</div>
    </div>
  );
};

TravelTime.prototypes = {
  travel_time: PropTypes.number
};

export default TravelTime;
