import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { RiInformationLine } from "@remixicon/react";
import {
  updateShowingInstructionsChanges,
  saveShowingInstructionsChanges
} from "~brokerage/actions/listings";
import { Form, Row, Label, Controls } from "~brokerage/components/shared/Form";
import Button from "~brokerage/components/shared/Button";
import ButtonSet from "~brokerage/components/shared/ButtonSet";
import Loader from "~brokerage/components/shared/Loader";
import Switch from "~brokerage/components/shared/Switch";
import ShowingAccess from "~brokerage/components/shared/EditShowingInstructions/ShowingAccess";
import { setPreviousAdvanceNoticeDuration } from "~brokerage/actions/listings";
import css from "~brokerage/components/listings/edit/Instructions/index.sass";

import AllowShowingOverlap from "~brokerage/components/shared/EditShowingInstructions/AllowShowingOverlap";
import MaxAppointmentLength from "~brokerage/components/shared/EditShowingInstructions/MaxAppointmentLength";
import BuyerNameRequired from "~brokerage/components/shared/EditShowingInstructions/BuyerNameRequired";
import AdvanceNotice from "~brokerage/components/shared/EditShowingInstructions/AdvanceNotice";
import RequestHandling from "~brokerage/components/shared/EditShowingInstructions/RequestHandling";
import ShowingArrangement from "~brokerage/components/shared/EditShowingInstructions/ShowingArrangement";
import InitialMessage from "~brokerage/components/shared/EditShowingInstructions/InitialMessage";
import RequestCallback from "~brokerage/components/shared/EditShowingInstructions/RequestCallback";
import AdditionalInstructions from "~brokerage/components/shared/EditShowingInstructions/AdditionalInstructions";
import NoShowingReason from "./NoShowingReason";
import { buildInstructionsPayload } from "../utils";
import { REQUIRE_APPROVAL, INSTANT_DECLINED } from "../constants";

export class Instructions extends React.PureComponent {
  static propTypes = {
    listingId: PropTypes.string,
    showingInstructions: PropTypes.object,
    afterSaveHandler: PropTypes.func,
    dispatch: PropTypes.func
  };

  state = {
    hasChanged: false
  };

  updateHasChanged() {
    if (!this.state.hasChanged) this.setState({ hasChanged: true });
  }

  changePayload = event => {
    const input = event.target;
    let payload;

    if (!input) {
      payload = event;
    } else {
      if (input.type === "checkbox") {
        payload = { [input.name]: input.checked };
      } else {
        payload = { [input.name]: input.value };
      }
    }

    return payload;
  };

  handleChange = event => {
    const payload = this.changePayload(event);
    this.props.dispatch(updateShowingInstructionsChanges(payload));
    this.updateHasChanged();
  };

  handleShownChange = event => {
    const value = event.target.checked;
    const payload = {
      shown: value,
      requestHandling: value ? REQUIRE_APPROVAL : INSTANT_DECLINED
    };
    this.props.dispatch(updateShowingInstructionsChanges(payload));
    this.updateHasChanged();
  };

  saveData = () => {
    const payload = buildInstructionsPayload(this.props);

    this.props.dispatch(
      saveShowingInstructionsChanges(this.props.listingId, payload)
    );
  };

  isFormValid() {
    return this.refs.form.validate();
  }

  handleSave = (callNextClick = true) => {
    if (this.state.hasChanged && this.isFormValid()) this.saveData();
    if (callNextClick) this.props.afterSaveHandler();
  };

  render() {
    const {
      showingInstructions,
      mlsBuyerRequiredEnabled,
      previousAdvanceNoticeDuration,
      showingInstructions: {
        showingEnabledByMls,
        buyerNameRequired,
        access,
        combo,
        brand,
        allowShowingOverlap,
        maxOverlappingShowingsCount,
        maxAppointmentDuration,
        requestHandling,
        accompanyAgent,
        accompanySeller,
        initialMessage,
        callbackRequest,
        message,
        noShowReason,
        noShowReasonOptions
      }
    } = this.props;

    if (!showingInstructions) {
      return <Loader active />;
    }

    return (
      <Form onSubmit={this.handleSave} validate ref="form">
        <Row offset="0" class={css.enableShowing}>
          <div className={css.label}>
            <Label valign="switch">Allow property to be shown?</Label>
          </div>
          <Controls>
            <div className={css.toggle}>
              <Switch
                name="shown"
                checked={showingInstructions.shown}
                onChange={this.handleShownChange}
                isDisabled={showingEnabledByMls}
              />
              {showingEnabledByMls && (
                <div className={css.info}>
                  <RiInformationLine color="#999" className={css.infoIcon} />
                  <span className={css.infoText}>
                    This button reflects the showing choice you made in your
                    MLS. To change this selection, please edit your showing
                    options directly in the MLS.
                  </span>
                </div>
              )}
            </div>
          </Controls>
        </Row>
        {(() => {
          if (showingInstructions.shown) {
            return (
              <div>
                <AllowShowingOverlap
                  allowShowingOverlap={allowShowingOverlap}
                  maxOverlappingShowingsCount={maxOverlappingShowingsCount}
                  handleChange={this.handleChange}
                />
                <MaxAppointmentLength
                  handleChange={this.handleChange}
                  maxAppointmentDuration={maxAppointmentDuration}
                />
                <RequestHandling
                  handleChange={this.handleChange}
                  requestHandling={requestHandling}
                />
                <InitialMessage
                  requestHandling={requestHandling}
                  initialMessage={initialMessage}
                  handleChange={this.handleChange}
                />
                <ShowingAccess
                  access={access}
                  combo={combo}
                  brand={brand}
                  handleChange={this.handleChange}
                />
                <ShowingArrangement
                  accompanyAgent={accompanyAgent}
                  accompanySeller={accompanySeller}
                  handleChange={this.handleChange}
                />
                <RequestCallback
                  callbackRequest={callbackRequest}
                  handleChange={this.handleChange}
                />
                <AdditionalInstructions
                  message={message}
                  handleChange={this.handleChange}
                />
                <BuyerNameRequired
                  mlsBuyerRequiredEnabled={mlsBuyerRequiredEnabled}
                  buyerNameRequired={buyerNameRequired}
                  handleChange={this.handleChange}
                />
                <AdvanceNotice
                  showingInstructions={showingInstructions}
                  handleChange={this.handleChange}
                  previousAdvanceNoticeDuration={previousAdvanceNoticeDuration}
                  setInitialAdvanceNoticeDuration={
                    setPreviousAdvanceNoticeDuration
                  }
                  dispatch={this.props.dispatch}
                />
              </div>
            );
          } else {
            return (
              <NoShowingReason
                noShowReason={noShowReason}
                options={noShowReasonOptions}
                handleChange={this.handleChange}
              />
            );
          }
        })()}

        <Row>
          <ButtonSet align="right">
            <Button variant="primary" type="submit">
              Done
            </Button>
          </ButtonSet>
        </Row>
      </Form>
    );
  }
}

function mapStateToProps(state) {
  const {
    listings: {
      single: {
        showingInstructions: {
          entity: showingInstructions,
          isFetching,
          errors,
          previousAdvanceNoticeDuration
        }
      }
    },
    currentUser: { mlsBuyerRequiredEnabled }
  } = state;

  return {
    showingInstructions,
    isFetching,
    errors,
    previousAdvanceNoticeDuration,
    mlsBuyerRequiredEnabled
  };
}
export default connect(mapStateToProps, null, null, { withRef: true })(
  Instructions
);
