import React from "react";

import css from "./Header.sass";
import { RiCloseLine } from "@remixicon/react";
import PropertyThumbnail from "~brokerage/components/shared/PropertyThumbanil";
import BroadcastOptOutToggle from "../BroadcastOptOutToggle";

const Header = ({
  closeModal,
  address,
  listingPhoto,
  isListingAgent,
  broadcastEnabled,
  onBroadcastSettingChange
}) => (
  <div className={css.mainHeader}>
    <div className={css.rowLeft}>
      <PropertyThumbnail className={css.thumbnail} image={listingPhoto} />
      <div className={css.titleAddress}>
        <div className={css.statusHeader}>Feedback Survey</div>
        <div className={css.address}>{address}</div>
      </div>
    </div>
    <div className={css.rowRight}>
      <RiCloseLine
        onClick={closeModal}
        size="1.4em"
        className={css.closeIcon}
      />
      {!isListingAgent && (
        <BroadcastOptOutToggle
          broadcastEnabled={broadcastEnabled}
          onChange={onBroadcastSettingChange}
          compactView={false}
        />
      )}
    </div>
  </div>
);

export default Header;
