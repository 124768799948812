import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import css from "./index.sass";
import { Footer } from "~brokerage/components/modals/BaseModal";
import Button from "~brokerage/components/shared/Button";
import { RiReplyFill } from "@remixicon/react";

import CalcFastestRoute from "./CalcFastestRoute";
import { ShowingContext } from "~brokerage/components/shared/Timeline/ShowingProvider";
import { callApi } from "~brokerage/middlewares/api";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { addAppointments } from "~brokerage/actions/routes";
import {
  buildAddAppointmentsPayload,
  buildRoutesPayload
} from "~brokerage/components/modals/utils";

function Actions({
  nextLabel,
  currentFastestRouteShowing,
  setCurrentFastestRouteShowing,
  step,
  setStep,
  nextDisabled,
  time,
  name,
  dispatch,
  mode,
  selectedRoute,
  selected,
  restrictions,
  buyers
}) {
  const history = useHistory();
  const { times } = useContext(ShowingContext);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (mode === "existing") {
      const payload = buildAddAppointmentsPayload(selected, buyers);
      dispatch(addAppointments(selectedRoute.routeId, payload));
      return;
    }

    if (step === "date") {
      setStep("time");
    } else {
      const payload = buildRoutesPayload({
        name,
        times,
        buyers
      });

      setLoading(true);
      callApi(`routes`, {}, payload, "post").then(
        response => {
          setLoading(false);
          const { id } = response.data.data.route;
          toast.success("Route scheduled successfully.");
          history.push(`/my_showings/${id}/schedule`);
        },
        error => {
          setLoading(false);
          toast.error(
            error.response?.data?.error ||
              "Sorry, there was an error scheduling this showing. Please try again."
          );
        }
      );
    }
  };

  const hasConflicts =
    step !== "date" &&
    times.some(s => s.conflicts === "passed" || s.conflicts === "unavailable");

  const isAppointmentsEmpty = step === "date" && isEmpty(selected);
  const disableNextButton =
    loading || nextDisabled || hasConflicts || isAppointmentsEmpty;

  return (
    <Footer>
      <div className={css.buttonContainer}>
        <div className={css.left}>
          {step !== "date" && (
            <CalcFastestRoute
              time={time}
              currentFastestRouteShowing={currentFastestRouteShowing}
              setCurrentFastestRouteShowing={setCurrentFastestRouteShowing}
              restrictions={restrictions}
            />
          )}
        </div>
        <div className={css.right}>
          {step !== "date" && (
            <Button
              type="button"
              float="right"
              variant="outline"
              onClick={() => setStep("date")}
            >
              <RiReplyFill size={14} className={css.prevIcon} />
              Previous
            </Button>
          )}
          <Button
            onClick={handleSubmit}
            float="right"
            variant="primary"
            disabled={disableNextButton}
          >
            {nextLabel}
          </Button>
        </div>
      </div>
    </Footer>
  );
}

export default connect()(Actions);
