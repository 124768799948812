import React from "react";
import css from "./SurveyListItem.sass";
import { RiPencilLine, RiDeleteBin7Line } from "@remixicon/react";

export default function SurveyListItem({ survey, onEditClick, onDeleteClick }) {
  const { id, name } = survey;
  return (
    <div className={css.container}>
      <p className={css.name}>{name}</p>
      <div className={css.buttons}>
        <button className={css.editBtn} onClick={() => onEditClick(id)}>
          <RiPencilLine size={16} />
        </button>
        <button className={css.deleteBtn} onClick={() => onDeleteClick(id)}>
          <RiDeleteBin7Line size={16} />
        </button>
      </div>
    </div>
  );
}
