import React from "react";
import {
  RiQuestionFill,
  RiUserAddFill,
  RiUserUnfollowFill
} from "@remixicon/react";

import css from "./index.sass";

import { eventIcons, eventColors } from "~brokerage/app/constants/events.js";

const EventIcon = ({ status, text }) => {
  const Icon = eventIcons[status] || MiscIcon(status, text);
  const color = eventColors[status] || eventColors["misc"];
  return (
    <div className={css.iconContainer}>
      <Icon color={color} size="24px" style={{ backgroundColor: "#f5f8fa" }} />
    </div>
  );
};

const MiscIcon = (status, text) => {
  if (status === "event") {
    if (text.endsWith("to the conversation")) {
      return RiUserAddFill;
    } else if (text.endsWith("from the conversation")) {
      return RiUserUnfollowFill;
    }
  }
  return RiQuestionFill;
};

export default EventIcon;
