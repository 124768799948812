import mapKeys from "lodash/mapKeys";

import {
  RiAlertFill,
  RiIndeterminateCircleFill,
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiDraftFill,
  RiCompass4Fill,
  RiQuestionFill
} from "@remixicon/react";

export const statusIcons = {
  approved: RiCheckboxCircleFill,
  declined: RiCloseCircleFill,
  pending: RiAlertFill,
  cancelled: RiIndeterminateCircleFill,
  not_sent: RiDraftFill,
  stop: RiCompass4Fill
};

export const statusColors = {
  approved: "#27ae60", //Green (Nephritis)
  declined: "#c0392b", //Red (Pomegranate)
  pending: "#f1c40f", //Yellow (Orange)
  cancelled: "#34495e", //Grey (Wet Asphalt)
  not_sent: "#7f8c8d", //Asbestos
  stop: "#000" //black
};

export const pastStatusColors = { ...statusColors, ...{ pending: "#7f8c8d" } };

export const statusText = {
  approved: "Approved",
  declined: "Declined",
  cancelled: "Cancelled",
  pending: "Requested"
};

export const DEFAULT_SHOWING_REASON = "first_showing";

export const reasonText = {
  first_showing: "Showing",
  return_showing: "Return Showing",
  appraisal: "Appraisal",
  inspection: "Inspection",
  walkthrough: "Walk-Through"
};

export const pastStatusText = { ...statusText, ...{ pending: "Expired" } };

export const statusTextColors = { ...statusColors, ...{ pending: "#f39c12" } };

export const titleAllKeys = dict =>
  mapKeys(dict, (v, k) => k[0].toUpperCase() + k.substr(1));

export const statusIcon = status => statusIcons[status] || RiQuestionFill;

export const statusColor = (status, isPassed = false) =>
  (isPassed ? pastStatusColors : statusColors)[status];

export const formatStatusText = (status, isPassed = false) =>
  (isPassed ? pastStatusText : statusText)[status];

//------------------------------------------------------
//Possible States
//   state :pending - yellow caution
//   state :approved - green circle tick
//   state :declined - red circle X
//   state :cancelled - grey circle strikethrough
//   state :not_sent, initial: true, exit: :unmark_draft
//   state :removed
//------------------------------------------------------
