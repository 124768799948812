import React from "react";
import css from "./Heading.sass";
import { RiCheckLine } from "@remixicon/react";
import SubHeading from "./SubHeading";
import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";

const Heading = ({
  showable,
  steps,
  currentStep,
  currIndex,
  listingId,
  isEditDateTimeOpen,
  setIsEditDateTimeOpen,
  appointmentReason,
  isListingOwnedByCurrentUserOrOfficeTeam
}) => {
  return (
    <>
      <div className={css.headingRow}>
        <div className={css.heading}>{steps[currIndex].heading}</div>
        {showable && (
          <SubHeading
            step={currentStep}
            listingId={listingId}
            isEditDateTimeOpen={isEditDateTimeOpen}
            setIsEditDateTimeOpen={setIsEditDateTimeOpen}
            appointmentReason={appointmentReason}
            isListingOwnedByCurrentUserOrOfficeTeam={
              isListingOwnedByCurrentUserOrOfficeTeam
            }
          />
        )}
      </div>
      {showable && (
        <div className={css.steps}>
          {steps.map((s, index) => (
            <div
              key={s.id}
              className={composeClassName(
                css,
                "step",
                currentStep === s.id
                  ? "active"
                  : currIndex > index
                  ? "complete"
                  : ""
              )}
            >
              <div className={css.num}>
                {currIndex > index ? <RiCheckLine size={16} /> : index + 1}
              </div>
              <div className={css.label}>{s.label}</div>
              {index < steps.length - 1 && <div className={css.line} />}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Heading;
