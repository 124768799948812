import React from "react";
import css from "./InvalidGeoWarning.sass";
import { RiInformationFill } from "@remixicon/react";

const InvalidGeoWarning = () => {
  return (
    <div className={css.base}>
      <RiInformationFill size={16} className={css.icon} />
      This listing cannot be found on a map. Travel times cannot be estimated.
    </div>
  );
};

export default InvalidGeoWarning;
