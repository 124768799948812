import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { RiPhoneLine, RiMailLine } from "@remixicon/react";

import fetch from "~brokerage/libs/fetch.js";
import AppLogo from "~brokerage/components/shared/AppLogo";

import css from "./index.sass";

import { Row, Label, Controls } from "~brokerage/components/shared/Form";
import Loader from "~brokerage/components/shared/Loader";
import Switch from "~brokerage/components/shared/Switch";
import { Link } from "react-router-dom";

const Toggle = ({ text, value, onChange, Icon }) => (
  <Row>
    <Label valign="switch" variant="mobileFriendly">
      <div className={css.labelInner}>
        <Icon size={18} className={css.icon} />
      </div>
      {text}
    </Label>
    <Controls>
      <Switch checked={value} onChange={onChange} />
    </Controls>
  </Row>
);

const UnsubscribeEmailPage = () => {
  const [notifyEmail, setNotifyEmail] = useState(false);
  const [notifyPhone, setNotifyPhone] = useState(false);
  const [sellerDetails, setSellerDetails] = useState({});
  const [loading, setLoading] = useState(true);

  const queryParams = new URLSearchParams(window.location.search);
  const sellerAuthToken = queryParams.get("token");
  const showingId = queryParams.get("showing");

  const { firstName } = sellerDetails;

  const pushData = {
    showing: { unique_id: showingId },
    brokerage_contact: {
      notify_email: notifyEmail,
      notify_phone: notifyPhone
    }
  };

  const setDataFromResponse = ({
    data: {
      notify_email: notifyEmail,
      notify_phone: notifyPhone,
      first_name: firstName
    }
  }) => {
    if (firstName) {
      setSellerDetails({ firstName: firstName });
    }
    setNotifyEmail(notifyEmail);
    setNotifyPhone(notifyPhone);
    setLoading(false);
  };

  const pushNotificationSettings = async () => {
    const toastMessages = {
      pending: "Updating Notification Settings",
      success: "Notification Settings Updated",
      error: "Sorry, Something went wrong"
    };
    setLoading(true);
    const response = await toast.promise(
      fetch(
        `/api/v2/seller/notifications`,
        {
          data: pushData,
          method: "patch",
          headers: { "Seller-Authorization": sellerAuthToken }
        },
        false
      ),
      toastMessages
    );
    await setDataFromResponse(response);
  };

  const fetchNotificationSettings = async () => {
    fetch(
      `/api/v2/seller/notifications`,
      {
        method: "get",
        headers: { "Seller-Authorization": sellerAuthToken }
      },
      false
    ).then(setDataFromResponse);
  };

  useEffect(fetchNotificationSettings, []);

  return (
    <div className={css.outerContainer}>
      <ToastContainer
        position="bottom-center"
        pauseOnFocusLoss
        pauseOnHover
        draggable
      />
      <AppLogo className={css.appLogo} variant="black" scale="1.75" />
      {!loading && (
        <div className={css.welcomeMessage}>Welcome, {firstName}</div>
      )}
      <div className={css.innerContainer}>
        <h2>Notification Settings</h2>
        <div className={css.buttonHolder}>
          {loading && <Loader modifier="z99" active />}
          <>
            <Toggle
              text="Notify via Email"
              value={notifyEmail}
              onChange={() => setNotifyEmail(!notifyEmail)}
              Icon={RiMailLine}
            />
            <Toggle
              text="Notify via Text Message"
              value={notifyPhone}
              onChange={() => setNotifyPhone(!notifyPhone)}
              Icon={RiPhoneLine}
            />
          </>
        </div>
        <button
          className={!loading ? css.submitButton : css.disabledSubmitButton}
          onClick={!loading ? pushNotificationSettings : () => {}}
        >
          Save Changes
        </button>
      </div>
      {showingId && (
        <div className={css.backToShowingLink}>
          <Link to="/">Back to Showing</Link>
        </div>
      )}
    </div>
  );
};

export default UnsubscribeEmailPage;
