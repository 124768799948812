import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import css from "../index.sass";
import Button from "~brokerage/components/shared/Button";
import { withRouter } from "react-router";
import Tooltip from "~brokerage/components/shared/Tooltip";
import { RiRouteFill, RiArrowUpDownFill } from "@remixicon/react";
import {
  FASTEST_ROUTE_DISABLED_REASONS,
  REORDER_ROUTE_DISABLED_REASONS,
  ADD_STOP_DISABLED_REASONS
} from "~brokerage/constants/routes/userMessages";
import {
  STATUS_CANCELLED,
  STATUS_DECLINED
} from "~brokerage/constants/showings/statuses";
import CalculateRouteModal from "./CalculateRouteModal";

import { Dropdown } from "~brokerage/components/shared/Form";

const Header = props => {
  const {
    routeId,
    startTime,
    showings,
    location,
    history,
    disableFastestRoute,
    disableReorder,
    optimized
  } = props;

  const [loading, setLoading] = useState(false);
  const [calcRouteModalOpen, setCalcRouteModalOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const selectableShowings = showings.filter(
    s => ![STATUS_DECLINED, STATUS_CANCELLED].includes(s.status.toLowerCase())
  );

  useEffect(() => {
    if (selectableShowings.length) {
      setSelected(selectableShowings[0]);
    }
  }, [showings]);

  const disabledFastestRouteReason =
    disableFastestRoute && FASTEST_ROUTE_DISABLED_REASONS[disableFastestRoute];

  const disableReorderReason =
    disableReorder && REORDER_ROUTE_DISABLED_REASONS[disableReorder];

  const disabledStopReason =
    disableReorder && ADD_STOP_DISABLED_REASONS[disableReorder];

  const reorderRoute = () => {
    history.push({
      pathname: location.pathname,
      query: {
        ...location.query,
        modal: "edit_appointment_time",
        routeId
      }
    });
  };

  const addStopChange = ({ value }) => {
    switch (value) {
      case "appointment":
        history.push({
          pathname: `/my_showings/${routeId}/add-appointments`
        });
        break;
      case "stop":
        history.push({
          pathname: location.pathname,
          query: {
            ...location.query,
            modal: "add_stop"
          }
        });
    }
  };

  return (
    <div className={css.header}>
      <div>
        <Tooltip
          disabled={!disableFastestRoute}
          text={disabledFastestRouteReason}
        >
          <Button
            modifier="flexCenter"
            disabled={
              loading || disableFastestRoute || selectableShowings.length < 2
            }
            onClick={() => setCalcRouteModalOpen(true)}
          >
            <RiRouteFill size={16} className={css.icon} />
            Fastest Route
          </Button>
        </Tooltip>
      </div>
      <div>
        <Tooltip disabled={!disableReorder} text={disabledStopReason}>
          <Dropdown
            title="Add Stop"
            options={[
              { label: "Add New Appointment", value: "appointment" },
              { label: "Non-MLS stop", value: "stop" }
            ]}
            selected={""}
            variant="blue"
            onChange={addStopChange}
            disabled={!!disableReorder}
          />
        </Tooltip>
      </div>
      <div>
        <Tooltip disabled={!disableReorder} text={disableReorderReason}>
          <Button
            modifier="flexCenter"
            disabled={disableReorder}
            onClick={reorderRoute}
          >
            <RiArrowUpDownFill size={16} className={css.icon} />
            Re-order Route
          </Button>
        </Tooltip>
      </div>
      <CalculateRouteModal
        isOpen={calcRouteModalOpen}
        setIsOpen={setCalcRouteModalOpen}
        selected={selected}
        setSelected={setSelected}
        selectableShowings={selectableShowings}
        loading={loading}
        setLoading={setLoading}
        showings={showings}
        optimized={optimized}
        startTime={startTime}
      />
    </div>
  );
};

Header.prototypes = {
  routeId: PropTypes.number,
  dispatch: PropTypes.func
};

export default withRouter(Header);
