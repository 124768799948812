import React from "react";
import { RiCheckLine } from "@remixicon/react";

import { composeClassName } from "~brokerage/libs/helpers/ClassNameHelper";
import { STEPS } from "./constants";
import SelectTime from "./steps/SelectTime";
import SelectDate from "./steps/SelectDate";
import ShowingAccess from "./steps/ShowingAccess";

import { steps } from "./utils";
import css from "./index.sass";

const Body = ({
  currentStep,
  showingData,
  times,
  showListingRestrictions,
  staticAppointments,
  loadingRestrictions,
  handleChange,
  rescheduleAndApproveShowing,
  setShowingAccess,
  showingAccess,
  appointmentReason
}) => {
  const currIndex = steps[currentStep].index;

  const changeDetails = update_params => {
    setShowingAccess({ ...showingAccess, ...update_params });
  };

  const resetDetails = () => setShowingAccess(showingData.local_details);

  const stepComponent = currentStep => {
    switch (currentStep) {
      case STEPS.time:
        return (
          <SelectTime
            data={showingData}
            times={times}
            showListingRestrictions={showListingRestrictions}
            staticAppointments={staticAppointments}
            loadingRestrictions={loadingRestrictions}
            appointmentReason={appointmentReason}
          />
        );
      case STEPS.date:
        return <SelectDate times={times} handleChange={handleChange} />;
      case STEPS.approval:
        return (
          <ShowingAccess
            changeDetails={changeDetails}
            resetDetails={resetDetails}
            isInstructionsEditable={true}
            showingAccess={showingAccess}
          />
        );
      default:
        return null;
    }
  };

  const stepLabel = () => {
    return (
      <div className={css.steps}>
        {Object.keys(STEPS).map((step, index) => {
          if (steps[step].disabled(rescheduleAndApproveShowing)) return null;

          return (
            <div
              key={step}
              className={composeClassName(
                css,
                "step",
                currentStep === step
                  ? "active"
                  : currIndex > index
                  ? "complete"
                  : ""
              )}
            >
              <div className={css.num}>
                {currIndex > index ? <RiCheckLine size={16} /> : index + 1}
              </div>
              <div className={css.label}>{steps[step].label}</div>
              {(index === 0 ||
                (rescheduleAndApproveShowing && index === 1)) && (
                <div className={css.line} />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={css.content}>
        {stepLabel()}
        {stepComponent(currentStep)}
      </div>
    </>
  );
};

export default Body;
